<script>
import api from "@/api";
import Loader from "vue-element-loading";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,
      email: "",
      phone: "",
      bookingData: null,
      previousMpesaPrompts: [],
    };
  },
  methods: {
    submit() {
      this.loading = true;
      api.axios
        .post("/tizi/api/paystack-payment-prompts/", {
          phone: this.phone,
          email: this.email,
          schedule: this.bookingData.id,
        })
        .then(() => {
          alert(`Check your phone ${this.phone} for a prompt.`);
          this.$router.push({ name: "UserBookings" });
        })
        .catch((err) => {
          console.error(err);
          alert("Error initiating prompt.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    decrLimitedCountToken() {
      const companyId = this.bookingData.space_location.space_company.id;
      return api.axios
        .get(`tizi/api/companies/${companyId}/decr_limited_count_token/`)
        .then((response) => {
          if (!response.data.can_make_payment) {
            this.$router.push({ name: "Notifications" });
          }
        });
    },
    getBooking() {
      return api.axios
        .get("tizi/api/schedules/" + this.$route.params.id)
        .then((response) => {
          this.bookingData = response.data;
          if (!this.bookingData.can_request_payment) {
            alert(
              "Tizi cannot push M-Pesa prompts at this time. Manually submit payment."
            );
            this.$router.push({ name: "Notifications" });
            return;
          }
        })
        .catch((err) => {
          console.error(err);
          alert(
            "Error retrieving booking information. Cannot use M-Pesa prompt."
          );
          this.$router.push({ name: "Notifications" });
          return;
        });
    },
    getPreviousMpesaPrompts() {
      return api.axios
        .get(`tizi/api/paystack-payment-prompts/?schedule=${this.scheduleId}`)
        .then((response) => {
          this.previousMpesaPrompts = response.data.results;
        });
    },
  },
  computed: {
    scheduleId() {
      return this.$route.params.id;
    },
    alreadyPrompted() {
      return this.previousMpesaPrompts.length > 0;
    },
    canPromptForAmount() {
      if (!this.email) {
        return false;
      }
      if (!this.phone) {
        return false;
      }
      if (!this.bookingData) {
        return false;
      }
      if (!this.bookingData.can_request_payment) {
        return false;
      }
      if (this.previousMpesaPrompts.length > 0) {
        return false;
      }

      return true;
    },
  },
  async created() {
    const promises = [];
    promises.push(this.getBooking());
    promises.push(this.getPreviousMpesaPrompts());
    await Promise.all(promises);
    this.loading = false;
    if (!this.bookingData) {
      alert("Invalid booking data.");
      this.$router.push({ name: "UserBookings" });
      return;
    }
    if (this.bookingData.active) {
      alert("Booking is already approved.");
      this.$router.push({
        name: "UserBookingsID",
        params: { ID: this.bookingData.id },
      });
      return;
    }
    if (this.bookingData.payments_request_mode == "limited_count") {
      this.loading = true;
      await this.decrLimitedCountToken();
      this.loading = false;
    }
    const authData = this.$store.state.Auth.authData;
    if (authData) {
      if (authData.email) {
        this.email = authData.email;
      }
      if (authData.phone) {
        this.phone = authData.phone;
      }
    }
  },
};
</script>

<template>
  <div>
    <form action.prevent="submit" class="pt-3">
      <Loader :active="loading" />
      <div class="container">
        <div class="row justify-content-center">
          <div class="col col-md-6">
            <!-- FORM GROUP START -->
            <div class="form-group row mb-3">
              <b for="exampleInputEmail1" class="col-12 col-md-3 mb-2">Your email address:</b>
              <div class="col">
                <input name="email" class="form-control" id="exampleInputEmail1" placeholder="Email address"
                  type="email" v-model="email" required />
              </div>
            </div>

            <div class="form-group row mb-3">
              <b for="exampleInputPhone1" class="col-12 col-md-3 mb-2">
                Your phone:</b>
              <div class="col">
                <input name="phone" class="form-control" id="exampleInputPhone1" placeholder="Phone " type="text"
                  v-model="phone" required />
              </div>
            </div>

            <!-- AMOUNT-->
            <div class="form-group row mb-3" v-if="bookingData">
              <label for="exampleInputPhone1" class="col-6 col-md-3 mb-2">Amount (in KES):</label>
              <div class="col">KES {{ bookingData.requested_amount }} /=</div>
            </div>

            <div class="form-group row mb-3">
              <div class="col offset-md-3 fw-bold text-danger" v-if="alreadyPrompted">
                Already Prompted
              </div>
              <div class="col offset-md-3" v-else>
                <p>
                  Payment will be made to
                  <a href="https://paystack.com/" target="blank_"><strong class="text-success">Paystack
                      Limited</strong></a>.
                </p>

                <button class="btn btn-success" :disabled="!canPromptForAmount" @click.prevent="submit">
                  Send
                  <img width="80px" src="https://www.safaricom.co.ke/images/lifeimages/M-PESA-logo-2.png"
                    alt="M-Pesa" />
                  Prompt
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="container">
      <div class="row mt-5" v-if="previousMpesaPrompts.length > 0">
        <div class="col">
          <h1>M-Pesa Payment Prompts For Booking</h1>
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-responsive">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Reference</th>
                  <!-- <th>Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="pp of previousMpesaPrompts" :key="pp.id" :id="pp.id">
                  <td>{{ pp.email }}</td>
                  <td>{{ pp.phone }}</td>
                  <td>{{ pp.reference }}</td>
                  <!-- <td>{{ pp.status }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
